const Rails = require('@rails/ujs')

global.CheckEmail = function(element) {
  element.change(async function(e) {
    var email = e.target.value
    var $label = $(e.target).prev('label') 
    var response = await $.ajax(`/utilities/check_email?email=${email}`)
    if (response.isp_email) {
      $label.addClass('text-danger')
      $label.html("EMAIL: Please use your work email")
    } else {
      $label.removeClass('text-danger')
      $label.html("EMAIL")
    }
  });
};