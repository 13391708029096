global.OptionsSearch = function(element) {
  element.bind('input propertychange', function() {
    var filter = element.val();
    var searchTargetClass = element.data('target-class');
    var targetInfoAttribute = element.data('target-attribute');
    $('.'+searchTargetClass).each(function() {
      var container = $(this);
      var id = $(this).find('[data-search-attribute='+targetInfoAttribute+']').text();
      if (id.match(new RegExp(filter, 'i')) === null) {
        container.addClass('hidden-important');
      } else {
        container.removeClass('hidden-important');
      }
    });
  });
};
