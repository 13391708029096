global.ToggleSubmit = function(element) {
  element.click(function() {
    if (!element.data('propagate')) {
      event.stopPropagation();
    }

    var form = $(element).closest('form');
    var changed = $(element).closest('label').children('#js-count-changed').html() == 'true';
    var submit = element.data('submit') == 'yes';

    if (submit) {
      $('#js-search-results').html('<div class="loading-spinner"></div>');
      setTimeout(function(){
        form.submit();
      }, 200);
    }

    if (changed) {
      $('#js-search-results').html('<div class="loading-spinner"></div>')
      setTimeout(function(){
        form.submit();
      }, 200);
      $(element).closest('label').children('#js-count-changed').html('false');
    }


    var show = element.data('show');
    var hide = element.data('hide');
    var toggle = element.data('toggle');
    var selectedClass = element.data('selected-class');
    var background = element.data('background');

    $(show).show();
    $(hide).hide();
    $(toggle).toggle();
    $(background).hide();

    $(element).addClass(selectedClass);
    $(element).siblings().each(function() {
      $(this).removeClass(selectedClass);
    });

  });
};
