global.SlideDown = function(element) {
  element.click(function() {
    var show = element.data('show');
    var hide = element.data('hide');
    var toggle = element.data('toggle');
    var selectedClass = element.data('selected-class');

    $(show).slideDown(200);
    $(hide).slideUp(200);
    $(toggle).slideToggle(200);

    $(element).addClass(selectedClass);
    $(element).siblings().each(function() {
      $(this).removeClass(selectedClass);
    });

  });
};
