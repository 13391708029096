global.Slide = function(element) {
  element.click(function() {
    if (!element.data('propagate')) {
      event.stopPropagation();
    }

    var show = element.data('show');
    var hide = element.data('hide');
    var toggle = element.data('toggle');
    var toggleClass = element.data('toggle-class');
    var showText = element.data('show-text');
    var hideText = element.data('hide-text');

    $(toggle).toggle(100, function() {
        if($(this).is(':hidden')) {
          $(element).html(showText);
          $(element).removeClass(toggleClass);
        }
        else {
          $(element).html(hideText);
          $(element).addClass(toggleClass);
        }
    });

    $(show).slideDown(100);
    $(hide).slideUp(100);

    $(element).addClass(selectedClass);
    $(element).siblings().each(function() {
      $(this).removeClass(selectedClass);
    });

  });
};
