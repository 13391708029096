global.RadioButtonFilter = function(element) {
  var targetClass = element.data('target-class');
  var input = element.data('input');
  var chosen = element.data('chosen');

  element.change(function() {
    $(input).children('#js-count-changed').html('true');
    var count = $('.'+targetClass+':checked').length;
    if (count > 0) {
      $(input).addClass('active');
      $(input).children('#js-selection-name').html(chosen);
    } else {
      $(input).removeClass('active');
    }
  });
};
