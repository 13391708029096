const Rails = require('@rails/ujs')

global.DirtyFormModalClose = function($element) {
  $element.data('original-values', $element.serialize())
  
  $modal = $element.closest('.modal')

  $modal.on('hide.bs.modal', function (e) {
    if ($element.serialize() != $element.data('original-values')) {
      confirmation = confirm('Are you sure? You have unsaved hour changes. Click Cancel to return to form and then click Save.')
      if (!confirmation) {
        e.preventDefault()
      }
    }
  })
};