global.ToggleDropdown = function(element) {
  $(document).on('click', function(e) {
    var targetId = element.data('target');
    var clicked = $(e.target);
    var background = element.data('background');

    if(!$('#'+targetId).has(clicked).length && $('#'+targetId).is(':visible') && !clicked.is($('#'+targetId))) {
      // hide the drop down if:
      // 1. The clicked item isn't in the dropdown
      // 2. the dropdown is visible
      // 3. the clicked item isn't the dropdown
      $('#' + targetId).slideUp(100);
      $(background).hide();
      $('.search-form--grouped').removeClass('search-form--grouped__focused');
    } else if ($(element).has(clicked).length || clicked.is($(element))) {
      // show the drop down if:
      // 1. The clicked item is in the target element
      // 2. The clicked item is the element
      $('#' + targetId).slideDown(100);
      $(background).show();
      $('.search-form--grouped').addClass('search-form--grouped__focused');
    }
  });
};
