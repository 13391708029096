global.Toggle = function(element) {
  element.click(function() {
    if (!element.data('propagate')) {
      event.stopPropagation();
    }

    var show = element.data('show');
    var hide = element.data('hide');
    var toggle = element.data('toggle');
    var toggleClass = element.data('toggle-class');
    var showText = element.data('show-text');
    var hideText = element.data('hide-text');

    $(toggle).toggle(0, function() {
        if($(this).is(':hidden')) {
          $(element).html(showText);
          $(element).removeClass(toggleClass);
        }
        else {
          $(element).html(hideText);
          $(element).addClass(toggleClass);
        }
    });

    $(show).show();
    $(hide).hide();

    // TODO: JOEL: Not sure what this was supposed to do but selectedClass doesn't exist and is throwing error
    // $(element).addClass(selectedClass);
    // $(element).siblings().each(function() {
    //   $(this).removeClass(selectedClass);
    // });

  });
};
