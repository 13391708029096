global.TextAreaCounter = function(element) {
  var max = element.data('max');
  var counter = element.data('counter');

  element.keydown(function(e) {
    if (e.which < 0x20) {
      // e.which < 0x20, then it's not a printable character
      // e.which === 0 - Not a character
      return;     // Do nothing
    }
    if (this.value.length > max) {
      // Maximum exceeded
      this.value = this.value.substring(0, max);
    }
  });

  element.bind('input change', function(e) {
    if (this.value.length > max) {
      // Maximum exceeded
      this.value = this.value.substring(0, max);
    }

    $(counter).html(this.value.length);
  });
};
