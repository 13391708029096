const Rails = require('@rails/ujs')

global.SubmitFormBackground = function(element) {
  element.change(function() {
    console.log("changed")
    var form = element[0]
    var data = Rails.serializeElement(form)
    var url = form.getAttribute('action') || location.href
    Rails.ajax({
      url, 
      data: data,
      type: form.method,
      dataType: 'script'
    })
  });
};