global.AppointmentHours = function(element) {
  var start = element.data('start');
  var end = element.data('end')

  var picker = new AppointmentPicker($(element)[0], { mode: '12h', startTime: start, endTime: end, large: true, title: 'Avaiable Hours'});
};




