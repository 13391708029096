global.SearchProviders = function(element) {

  var delay = (function(){
    var timer = 0;
    return function(callback, ms){
    clearTimeout(timer);
    timer = setTimeout(callback, ms);
   };
  })();

  $(element).keyup(function(){
    if ($(element).val().length > 1) {
      delay(function(){
        $.ajax({
              url: '/utilities/search_providers',
              type: 'get',
              data: {
                name: $(element).val()
              },
              dataType: 'script'
        });
      }, 100 );
    } else {
      $('#js-search-results').html('');
    }
  });
}
