/* global Elemental:true */

require("./vendor/elementaljs")
require("./elementaljs-behaviors")

import ahoy from 'ahoy.js'

var ready = function(event) {
  console.log('FLT Init...')
  global.Elemental.load(document); //TODO: JOEL: Fix this syntax
  $('.js-phone-number').mask('(000) 000-0000');
  if (typeof gtag === 'function') {
    gtag('config', 'UA-114911016-1', {'page_path': window.location.pathname})
  }


  $("#js-geocomplete-auto-submit").geocomplete({ country: "US", types: ["(regions)"] }).bind("geocode:result", function(event, result){
    console.log(result)
    $('#js-search-results').html('<div class="loading-spinner"></div>')
    setTimeout(function(){
      $("#js-geocomplete-auto-submit-form").submit();
    }, 200);
  });

  setTimeout(function(){
    if (global.initMap && $(".interactive-map").length) { global.initMap() } // TODO: JOEL: Explore this function call?
  }, 500);

  var $animation_elements = $('.animation-element');
  var $window = $(window);

  function check_if_in_view() {
    var window_height = $window.height();
    var window_top_position = $window.scrollTop();
    var window_bottom_position = (window_top_position + window_height);
    $.each($animation_elements, function() {
      var $element = $(this);
      var element_height = $element.outerHeight();
      var element_top_position = $element.offset().top;
      var element_bottom_position = (element_top_position + element_height);
      //check to see if this current container is within viewport
      if ((element_bottom_position >= window_top_position) &&
          (element_top_position <= window_bottom_position)) {
        $element.addClass('in-view');
      } else {
        //$element.removeClass('in-view');
      }
    });
  }

  $window.on('scroll resize', check_if_in_view);
  $window.trigger('scroll');

  // TODO: JOEL: Moved to page level script for now
  // window.fcWidget.init({
  //   token: "b296f83f-b4f3-4560-a8f8-1b80d18d1037",
  //   host: "https://wchat.freshchat.com"
  // });

  ahoy.configure({cookies: false});

  if ($('#js-geocomplete').length) { 
    $('#js-geocomplete').geocomplete({ country: "US", types: ["(regions)"] }) 
  }
  
  if ($('#js-geocomplete-2').length) { 
    $('#js-geocomplete-2').geocomplete({ country: "US", types: ["(regions)"] }) 
  }

};

$(document).on('turbolinks:load', ready);
