global.ToggleAutoSubmit = function(element) {
  $(document).on('click', function(e) {
    var targetId = element.data('target');
    var clicked = $(e.target);
    var background = element.data('background');
    var changed = $(element).children('#js-count-changed').html() == 'true';

    if(!$('#'+targetId).has(clicked).length && $('#'+targetId).is(':visible') && !clicked.is($('#'+targetId))) {
      // hide the drop down if:
      // 1. The clicked item isn't in the dropdown
      // 2. the dropdown is visible
      // 3. the clicked item isn't the dropdown
      if (changed) {
        $('#js-search-results').html('<div class="loading-spinner"></div>')
        setTimeout(function(){
          var form = $(element).closest('form');
          form.submit();
        }, 200);
        $(element).children('#js-count-changed').html('false')
      }


      $('#' + targetId).hide();
      $(background).hide();
    } else if ($(element).has(clicked).length || clicked.is($(element))) {
      // show the drop down if:
      // 1. The clicked item is in the target element
      // 2. The clicked item is the element
      $('#' + targetId).show();
      $(background).show();
    }
  });
};


