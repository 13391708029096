global.HiddenDefaultRadio = function(element) {
  element.click(function() {
    var target = element.data('default');
    //var checked = $(this).find("input:radio:first").prop("checked");
    var checked = element.data('checked');
    console.log('checked: ' + checked);

    if (checked == 'yes') {
      $(target).find("input:radio:first").prop('checked', true);
      element.data('checked', 'no');
    } else {
      element.data('checked', 'yes');
    }
  });
};
