const Rails = require('@rails/ujs')

global.SubmitParentFormOnClick = function(element) {
  element.click(function() {
    setTimeout(function(){
      var form = $(element).closest('form');
      Rails.fire(form[0],'submit')
    }, 200);
    $('#js-last-updated-date').html('Just Now');
    $('#js-search-results').html('<div class="loading-spinner"></div>');
    var div = element.data('div');
    $(div).html('<div class="loading-spinner"></div>')
  });
};
