global.ToggleDivTarget = function(element) {
  
  let target = element.data('behavior-target')
  let criteria = element.data('behavior-show-criteria')

  if (element.val() == criteria) {
    element.is(':checked') ? $(target).show() : $(target).hide()
  }

  element.click(function() {

    if (element.val() == criteria) {
      $(target).show()
    } else {
      $(target).hide()
    }

  });
};
