global.MultiCheckboxCounter = function(element) {
  var targetClass = element.data('target-class');
  var targetClassAll = element.data('target-class-all');
  var counter = element.data('counter');
  var counterAll = element.data('counter-all');
  var input = element.data('input');

  element.change(function() {
    $(element).closest('label').children('#js-count-changed').html('true');
    var count = $('.'+targetClass+':checked').length;
    var countAll = $('.'+targetClassAll+':checked').length;

    if (count > 0) {
      $(counter).html(' · '+count);
      $(input).addClass('active');
    } else {
      $(counter).html('');
      $(input).removeClass('active');
    }

    if (countAll > 0) {
      $(counterAll).html(' · '+countAll);
    } else {
      $(counterAll).html('');
    }
  });
};
