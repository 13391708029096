global.AhoyWebsiteClick = function(element) {
  element.click(function() {
    var siteId = element.data('site-id');
    // eslint-disable-next-line no-undef
    ahoy.track('Website Click', {
      site_id: siteId
    });

  });
};
