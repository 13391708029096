global.OperatingHourAdder = function(element) {
  element.click(function(event) {
    event.preventDefault();
    var form = $(element).closest('.js-hours-form');
    form.find('.js-save-button:first').show();
    var selectedDayVal = form.find('select[name="day"]:first').val();
    var dayVal = selectedDayVal;
    var openVal = form.find('select[name="operating_hour[open_at(5i)]"]:first').val();
    var closeVal = form.find('select[name="operating_hour[close_at(5i)]"]:first').val();
    var twentyFourChecked = form.find('.js-twenty-four-adder').find('input:checkbox:first').prop("checked");

    console.log(openVal + closeVal);

    var loopCount = 1;

    if (selectedDayVal == 7) {
      loopCount = 7;
      dayVal = 1;
    } else if (selectedDayVal == 8) {
      loopCount = 5;
      dayVal = 1;
    } else if (selectedDayVal == 9) {
      loopCount = 2;
      dayVal = 6;
    }

    for (i = 1; i <= loopCount; i++) {
      $(form).find('.add_fields:first').click();

      var dayField = $(form).find('.js-day-adder:last');
      var openField = $(form).find('.js-open-hour:last').find('select');
      var closeField = $(form).find('.js-close-hour:last').find('select');
      var twentyFourField = $(form).find('.js-24-check:last').find('input:checkbox:last');
      console.log(dayVal);

      if ( dayVal === "" ) {
        dayVal = 1;
      }

      dayField.val(dayVal);

      if (selectedDayVal > 6 ) {
        dayVal++;
        if (dayVal == 7) {
          dayVal = 0;
        }
      }

      openField.val(openVal);
      closeField.val(closeVal);
      if (twentyFourChecked) {
        twentyFourField.prop("checked", true).trigger("change");
      }
    }

    // reset picker
    form.find('select[name="day"]:first').val(null);
    form.find('select[name="operating_hour[open_at(5i)]"]:first').val('09:00:00');
    form.find('select[name="operating_hour[close_at(5i)]"]:first').val('17:00:00');
  });
};
