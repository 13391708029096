/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)



// Do this as early as possible so we catch any startup exceptions
import * as Sentry from '@sentry/browser';
if (process.env.SENTRY_DSN) {
  Sentry.init({ dsn: `${process.env.SENTRY_DSN}`, release: window.SENTRY_RELEASE, environment: `${process.env.RAILS_ENV}` })
} else {
  console.warn("Sentry tracking not enabled, process.env.SENTRY_DSN not found.")
}

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
// import 'bootstrap/dist/js/bootstrap'
import 'bootstrap';
require("tether")

import 'jquery-mask-plugin'
import 'geocomplete'

// Need this so images end up in manifest for image_pack_tag helpers etc
require.context('../images', true)

global.$ = $ //TODO: JOEL: Remove this, needed right now by page level script tags

//= require vendor/appointment-picker //TODO: JOEL: Is this used someplace?

// Main application init tied to turbolinks:load
require('../src/javascripts/init')





