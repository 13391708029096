global.ProviderUpdateEmail = function(element) {
  element.change(function() {
    params={};location.search.replace(/[?&]+([^=&]+)=([^&]*)/gi,function(s,k,v){params[k]=v})

    var delay = element.data('delay');
    var siteId = element.data('siteid');
    var newUrl = window.location.href.split('?')[0] + "?accurate=yes&delay="+delay + "&site_id="+siteId;

    window.location.href = newUrl;
  });
};