const Rails = require('@rails/ujs')

global.FancyCheck = function(element) {
  element.click(function() {
    checkbox = element.find('input');

    checkbox.prop("checked", !checkbox.prop("checked"));
    checked = checkbox.prop("checked");

    if (checked) {
      element.find('.round-checkbox > label').html('<div class="checkmark"></div>');
    } else {
      element.find('.round-checkbox > label').html('');
    }

    $(checkbox).trigger("change")    
  });
};
