global.ToggleResults = function(element) {
  element.click(function() {
    var map = $('#js-map-results');
    var list = $('#js-list-results');

    if (list.is(':hidden')) {
      list.show();
      map.hide();
      $(element).html('Show map')
    } else {
      map.show();
      list.hide();
      $(element).html('Show list')
    }

    // have to do this so g maps initializes properly
    map.css({top: 0, position:'relative'});

  });
};
