global.TwentyFourToggle = function(element) {
  $(element).off('change');
  $(element).change(function() {
    var toggle = element.data('toggle');
    var sibling = element.data('sibling');

    $(element).closest(sibling).siblings(toggle).toggle();

  });
};
