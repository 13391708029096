global.UpdateEmail = function(element) {
  element.change(function() {
    console.log('click!');
    params={};location.search.replace(/[?&]+([^=&]+)=([^&]*)/gi,function(s,k,v){params[k]=v})

    var currentDelay = params['delay'];
    var currentAccurate = params['accurate'];
    var delay = element.data('delay');
    var newUrl = location.href.replace("accurate="+currentAccurate+"&delay="+currentDelay, "accurate=yes&delay="+delay);
    window.location.href = newUrl;
  });
};

